import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import Input from '$ui/input';

import { getLocation } from '$utils/queryFunctions';

const PincodeField = ({
  name,
  onChange,
  onBlur,
  value,
  error,
  setFieldValue,
  stateFieldName = 'state',
  cityFieldName = 'city'
}) => {
  const handleChange = useCallback(
    async e => {
      onChange?.(e);
      const code = e.target.value;

      if (code.length !== 6) return;

      try {
        const loc = await getLocation(code);
        setFieldValue(stateFieldName, loc.state.toUpperCase());
        setFieldValue(cityFieldName, loc.district.toUpperCase());
      } catch (err) {
        toast.error(err.response?.data.message);
      }
    },
    [onChange, setFieldValue, stateFieldName, cityFieldName]
  );

  return (
    <Input
      name={name || 'pincode'}
      placeholder='pincode'
      onBlur={onBlur}
      onChange={handleChange}
      value={value}
      error={error}
    />
  );
};

export default PincodeField;
