import React from "react";

import Heading from "$ui/heading";

import UserLayout from "$components/layout/user";
import Account from "$components/account";
import EventsPanel from "$components/events/eventsPanel";

const AccountPage = () => {
  return (
    <UserLayout title="Account">
      <Account />

      {/* <section className="bg-white py-10 px-4">
        <Heading variant="tertiary-left">Upcoming events</Heading>

        <EventsPanel />
      </section> */}
    </UserLayout>
  );
};

export default AccountPage;
