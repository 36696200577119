import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useUser, useUpdateUser } from '$hooks/auth';

import Heading from '$ui/heading';
import Input from '$ui/input';
import Button from '$ui/button';
import PincodeField from '$ui/pincodeField';

import UserIcon from '$assets/user.svg';
import UploadIcon from '$assets/upload.svg';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const accountSchema = Yup.object().shape({
  fullName: Yup.string().trim().required('Please provide your name'),

  phone: Yup.string()
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Please provide your phone number'),

  dateOfBirth: Yup.date()
    .min('1946-12-31T18:30:00.000Z', 'Please provide correct date of birth')
    .max(new Date().toISOString(), 'Please provide correct date of birth')
    .required('Please provide your date of birth'),

  pincode: Yup.string()
    .matches(/^[0-9]+$/, 'Pincode can contain only digits')
    .min(6, 'Pincode must be of exactly 6 digits')
    .max(6, 'Pincode must be of exactly 6 digits')
    .required('Please provide your pincode'),

  state: Yup.string().required('Please provide the state in which you live'),

  city: Yup.string().required('Please provide the city in which you live'),

  address: Yup.string()
    .trim()
    .required('Please provide your current residential address')
});

const Account = () => {
  const { user } = useUser();
  const { mutateAsync } = useUpdateUser();
  const [hasUploaded, setHasUploaded] = useState();
  const [isUploading, setIsUploading] = useState();
  const container = useRef();

  const initialValues = useMemo(
    () => ({
      fullName: user?.fullName,
      phone: user?.phone,
      pincode: user?.pincode,
      state: user?.state,
      city: user?.city,
      address: user?.address,
      dateOfBirth:
        user?.dateOfBirth &&
        new Date(user?.dateOfBirth).toISOString().split('T')[0]
    }),
    [user]
  );

  const handleSubmit = useCallback(
    async values => {
      try {
        await mutateAsync(values);
        toast.success('Date updated successfully!');
      } catch (err) {
        toast.error(
          err.response?.data.message ||
            'Something went wrong while updating your profile'
        );
      }
    },
    [mutateAsync]
  );

  const handleProfilePicSubmission = useCallback(
    async e => {
      e.preventDefault();
      setIsUploading(true);

      const fileInput = e.target['profilePic'];
      const payload = new FormData();

      payload.append('photo', fileInput.files[0]);

      try {
        await mutateAsync(payload);
        fileInput.value = null;
        setHasUploaded(false);
        toast.success('Profile picture changed');
        container.current.scrollTop = 0;
      } catch (err) {
        console.log(err.response);
        toast.error('Could not upload your profile picture at the moment');
      } finally {
        setIsUploading(false);
      }
    },
    [mutateAsync]
  );

  return (
    <section
      ref={container}
      className='p-10 phone:px-5 h-screen scroll-smooth overflow-y-scroll tab-land:h-auto'>
      <Heading variant='tertiary-left'>Your profile</Heading>

      <div className={'flex justify-center mt-10'}>
        {user?.photo ? (
          <img
            src={user?.photo}
            alt='USER'
            className='w-24 h-24 object-cover rounded-full'
          />
        ) : (
          <div className='rounded-full p-2 border-4 border-gray-400'>
            <UserIcon className='fill-gray-600 h-16 w-16' />
          </div>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={accountSchema}>
        {formik => (
          <form
            className='mt-16 grid grid-cols-2 gap-x-10 gap-y-12 phone:grid-cols-none'
            onSubmit={formik.handleSubmit}>
            <Input
              name='fullName'
              placeholder='full name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              error={formik.touched.fullName && formik.errors.fullName}
            />

            <Input
              name='phone'
              placeholder='phone number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone}
            />

            <Input
              type='date'
              name='dateOfBirth'
              placeholder='date of birth'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dateOfBirth}
              error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
            />

            <PincodeField
              name='pincode'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pincode}
              error={formik.touched.pincode && formik.errors.pincode}
              setFieldValue={formik.setFieldValue}
            />

            <Input
              name='state'
              placeholder='state'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              error={formik.touched.state && formik.errors.state}
              disabled
            />

            <Input
              name='city'
              placeholder='city'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={formik.touched.city && formik.errors.city}
              disabled
            />

            <div className='col-span-full'>
              <Input
                name='address'
                placeholder='street address'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && formik.errors.address}
              />
            </div>

            <Button
              isLoading={formik.isSubmitting}
              type='submit'
              variant='filled'
              className='col-span-full justify-self-end'>
              Save changes
            </Button>
          </form>
        )}
      </Formik>

      <form onSubmit={handleProfilePicSubmission}>
        <Heading className='mt-24 mb-10' variant='tertiary-left'>
          Upload your profile picture
        </Heading>

        <div className='border-2 border-dotted border-primary-light flex flex-col items-center p-8 space-y-5'>
          <input
            type='file'
            name='profilePic'
            id='profilePic'
            accept='image/*'
            className='hidden'
            onChange={e => setHasUploaded(!!e.target.files)}
          />

          <UploadIcon />
          <p className='text-black'>
            {hasUploaded
              ? 'A file has been selected. Change it by clicking here'
              : 'Upload your profile picture by clicking'}{' '}
            <label
              htmlFor='profilePic'
              className='text-primary-light underline'>
              here
            </label>
          </p>
          <p className='text-xs'>
            File must be in in png, jpeg, jpg, webp or svg format
          </p>
        </div>

        <div className='text-right mt-8'>
          <Button
            type='submit'
            variant='filled'
            disabled={!hasUploaded}
            isLoading={isUploading}>
            Save changes
          </Button>
        </div>
      </form>
    </section>
  );
};

export default Account;
